import SortDirection from '@/common/table/types/sortDirection';
import {PaginatedServiceProviderResult} from '@/serviceProviders/model/paginatedServiceProviderResult';
import SortBy from '@/serviceProviders/model/sortBy';
import {ServiceProvidersService} from '@/serviceProviders/serviceProvidersService';
import ServiceProvider from "@/serviceProviders/model/ServiceProvider";
import {Trade} from "@/serviceProviders/model/trade";

export class ServiceProvidersPort {

    private serviceProvidersService: ServiceProvidersService;


    constructor(serviceProvidersService: ServiceProvidersService) {
        this.serviceProvidersService = serviceProvidersService;
    }

    registerServiceProvider(serviceProvider: ServiceProvider): Promise<string> {
        return this.serviceProvidersService.registerServiceProvider(serviceProvider);
    }

    updateServiceProvider(serviceProvider: ServiceProvider): Promise<ServiceProvider | null> {
        return this.serviceProvidersService.updateServiceProvider(serviceProvider);
    }

    deleteServiceProvider(): Promise<boolean> {
        return this.serviceProvidersService.deleteServiceProvider();
    }

    getAvailableTrades(): Promise<Trade[] | null> {
        return this.serviceProvidersService.getAvailableTrades();
    }

    getServiceProviderByUserKey(): Promise<ServiceProvider | null> {
        return this.serviceProvidersService.getServiceProviderByUserKey();
    }

    getServiceProviderByKey(serviceProviderKey: string): Promise<ServiceProvider | null> {
        return this.serviceProvidersService.getServiceProviderByKey(serviceProviderKey);
    }

    getServiceProviderNameByKey(userKey: string): Promise<string | null> {
        return this.serviceProvidersService.getServiceProviderNameByUserKey(userKey);
    }

    // getAvailableMeasures(): Promise<Measure[]> {
    //     return this.serviceProvidersService.getAvailableMeasures();
    // }

    public searchServiceProviders(searchTerm: string,
                                  measureFilterKey: string,
                                  sortBy: SortBy,
                                  sortDirection: SortDirection,
                                  page: number,
                                  pageSize: number): Promise<PaginatedServiceProviderResult> {

        return this.serviceProvidersService.searchServiceProviders(searchTerm, measureFilterKey, sortBy, sortDirection, page, pageSize);
    }
}
