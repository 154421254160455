enum SortBy {
    TYPE,
    NAME,
    FIXED_COST,
    COST,
    SAVING,
    AMORTISATION,
    BEG
}

export default SortBy;
