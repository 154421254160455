import QuickcheckProperty from "@/quickcheck/model/QuickcheckProperty";
import QuickcheckService from "@/quickcheck/quickcheckService";
import QuickcheckPropertyValidation from "@/quickcheck/model/QuickcheckPropertyValidation";
import QuickcheckResult from "@/quickcheck/model/QuickcheckResult";

export default class QuickcheckPort {
    private quickcheckService: QuickcheckService;

    constructor(quickcheckService: QuickcheckService) {
        this.quickcheckService = quickcheckService;
    }

    requestQuickcheck(property: QuickcheckProperty): Promise<QuickcheckResult> {
        return this.quickcheckService.requestQuickcheck(property);
    }

    validateQuickcheckProperty(property: QuickcheckProperty): QuickcheckPropertyValidation {
        return this.quickcheckService.validateQuickcheckProperty(property);
    }
}