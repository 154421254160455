import QuickcheckRepository from "@/quickcheck/repository/quickcheckRepository";
import QuickcheckProperty from "@/quickcheck/model/QuickcheckProperty";
import QuickcheckPropertyValidation from "@/quickcheck/model/QuickcheckPropertyValidation";
import ValidationStatus from "@/quickcheck/model/ValidationStatus";
import QuickcheckResult from "@/quickcheck/model/QuickcheckResult";


export default class QuickcheckService {
    private quickcheckRepository: QuickcheckRepository;

    constructor(quickcheckRepository: QuickcheckRepository) {
        this.quickcheckRepository = quickcheckRepository;
    }

    requestQuickcheck(property: QuickcheckProperty): Promise<QuickcheckResult> {
        return this.quickcheckRepository.requestQuickcheck(property);
    }

    validateQuickcheckProperty(property: QuickcheckProperty): QuickcheckPropertyValidation {
        return {
            buildingTypeValidity: this.validateBuildingType(property.buildingType),
            constructionYearValidity: this.validateConstructionYear(property.constructionYear),
            livingSpaceValidity: this.validateLivingSpace(property.livingSpace),
            numberOfRoomsValidity: this.validateNumberOfRooms(property.numberOfRooms),
            energyDemandValidity: this.validateEnergyDemand(property.energyDemand),
        };
    }

    private validateBuildingType(buildingType: string): ValidationStatus {
        if (buildingType === '') {
            return ValidationStatus.MISSING;
        }
        return ValidationStatus.OK;
    }

    private validateConstructionYear(constructionYear: number): ValidationStatus {
        if (constructionYear === 0 || isNaN(constructionYear)) {
            return ValidationStatus.MISSING;
        }
        if (constructionYear < 1600 ||
            constructionYear > (new Date()).getFullYear() ||
            constructionYear % 1 !== 0) {
            return ValidationStatus.ERROR;
        }
        return ValidationStatus.OK;
    }

    private validateLivingSpace(livingSpace: number): ValidationStatus {
        if (livingSpace === 0 || isNaN(livingSpace)) {
            return ValidationStatus.MISSING;
        }
        if (livingSpace < 10) {
            return ValidationStatus.ERROR;
        }
        return ValidationStatus.OK;
    }

    private validateNumberOfRooms(numberOfRooms: number): ValidationStatus {
        if (numberOfRooms === 0 || isNaN(numberOfRooms)) {
            return ValidationStatus.MISSING;
        }
        if (numberOfRooms < 1 ||
            numberOfRooms % 1 !== 0) {
            return ValidationStatus.ERROR;
        }
        return ValidationStatus.OK;
    }

    private validateEnergyDemand(energyDemand: number): ValidationStatus {
        if (energyDemand < 0 ||
            energyDemand > 500) {
            return ValidationStatus.ERROR;
        }
        if (energyDemand === 0) {
            return ValidationStatus.MISSING;
        }
        return ValidationStatus.OK;
    }

    private validateEnergyEfficiencyClass(energyEfficiencyClass: string): ValidationStatus {
        return ValidationStatus.OK;
    }
}