import { defineStore } from 'pinia'
import {useStorage} from "@vueuse/core";

export const serviceProviderStore = defineStore('serviceProvider', {
    state() {
        return {
            key: useStorage('key', ''),
        }
    },
    getters: {
        getKey: (state): string => {
            return state.key;
        }
    },
    actions: {
        updateCurrentServiceProviderKey(newKey: string) {
            this.key = newKey;
        },
        clearKey() {
            this.$reset();
        },
    }
});