import SortDirection from '@/common/table/types/sortDirection';
import {PaginatedMeasureOverviewResult} from '@/measures/model/paginatedMeasureOverviewResult';
import SortBy from '@/measures/model/sortBy';
import MeasureRepository from '@/measures/repository/measureRepository';
import {Measure} from "@/measures/model/measure";
import SelectCategory from "@/measures/model/selectCategory";
import {ImageAnalysisResponse} from "@/measures/model/imageAnalysisResponse";

export class MeasureService {

    private measureRepository: MeasureRepository;


    constructor(measureRepository: MeasureRepository) {
        this.measureRepository = measureRepository;
    }

    public async searchMeasures(searchTerm: string,
                                sortBy: SortBy,
                                sortDirection: SortDirection,
                                page: number,
                                pageSize: number, measureTypes: string[], minFixedCost: number, maxFixedCost: number, minCost: number, maxCost: number): Promise<PaginatedMeasureOverviewResult> {

        return this.measureRepository.searchMeasures(searchTerm, sortBy, sortDirection, page, pageSize, measureTypes, minFixedCost, maxFixedCost, minCost, maxCost);
    }

    public updateMeasure(measure: Measure): Promise<boolean> {
        return this.measureRepository.updateMeasure(measure);
    }

    public getMeasureByKey(measureKey: string): Promise<Measure | null> {
        return this.measureRepository.getMeasureByKey(measureKey);
    }

    public deleteMeasureByKey(measureKey: string): Promise<boolean> {
        return this.measureRepository.deleteMeasureByKey(measureKey);
    }

   public createTypeCategory(name: string, typeCategory: SelectCategory): Promise<boolean> {
        return this.measureRepository.createTypeCategory(name, typeCategory);
    }

    public getTypeCategories(typeCategory: SelectCategory): Promise<string[] | null> {
        return this.measureRepository.getTypeCategories(typeCategory);
    }

    public deleteTypeCategory(name: string, typeCategory: SelectCategory): Promise<boolean> {
        return this.measureRepository.deleteTypeCategory(name, typeCategory);
    }

    public requestImageAnalysis(image: string): Promise<ImageAnalysisResponse> {
        return this.measureRepository.requestImageAnalysis(image);
    }
}
