import Config from "@/config";
import QuickcheckProperty from "@/quickcheck/model/QuickcheckProperty";
import QuickcheckResult from "@/quickcheck/model/QuickcheckResult";

interface QuickcheckResponse {
    energyDemand: number,
    classRanges: number[],
    frequencies: number[],
    percentageOfBetterSimilarObjects: number,
    percentageOfBetterOverallObjects: number
}

export default class QuickcheckRepository {
    async requestQuickcheck(property: QuickcheckProperty): Promise<QuickcheckResponse> {
        try {
            const response = await fetch(`${Config.backendBaseUrl}/api/quickcheck/request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                },
                body: JSON.stringify(property),
            });
            const jsonResponse: QuickcheckResponse = await response.json();
            return QuickcheckRepository.mapToQuickcheckResult(jsonResponse);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private static mapToQuickcheckResult(quickcheckResponse: QuickcheckResponse): QuickcheckResult {
        return {
            energyDemand: quickcheckResponse.energyDemand,
            classRanges: quickcheckResponse.classRanges,
            frequencies: quickcheckResponse.frequencies,
            percentageOfBetterSimilarObjects: quickcheckResponse.percentageOfBetterSimilarObjects,
            percentageOfBetterOverallObjects: quickcheckResponse.percentageOfBetterOverallObjects
        };
    }


}
