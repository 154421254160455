import SortDirection from '@/common/table/types/sortDirection';
import Config from '@/config';
import SortBy from '@/serviceProviders/model/sortBy';
import {PaginatedServiceProviderResult} from '@/serviceProviders/model/paginatedServiceProviderResult';
import ServiceProvider from "@/serviceProviders/model/ServiceProvider";
import {Trade} from "@/serviceProviders/model/trade";
import {ServiceProvidersRepository} from "@/serviceProviders/repository/serviceProvidersRepository";

interface ServiceProviderResponse {
    key: string,
    userKey: string
    name: string,
    openingTimes: string,
    address: string,
    // availableMeasures: Measure[],
    offeredMeasureKeys: string[],
    tradeKeys: string[],
    additionalInformation: string,
}

interface ServiceProviderNameResponse {
    value: string
}

interface TradeResponse {
    trades: {
        key: {
            value: string
        },
        name: {
            value: string
        }
    }[]
}

interface ServiceProviderCreateResponse {
    key: string,
}

export class ServiceProvidersRepositoryImpl implements ServiceProvidersRepository {

    private serviceProvidersBackendUrl = `${Config.backendBaseUrl}/api/service-providers`;

    async searchServiceProviders(searchTerm: string,
                                 measureFilterKey: string,
                                 sortBy: SortBy,
                                 sortDirection: SortDirection,
                                 page: number,
                                 pageSize: number): Promise<PaginatedServiceProviderResult> {

        const sortParam = this.mapSortByToUrlParameterValue(sortBy);
        const orderParam = this.mapSortOrderToUrlParameterValue(sortDirection);
        const queryUrl = `${this.serviceProvidersBackendUrl}/search?searchTerm=${searchTerm}&filterBy=${measureFilterKey}&sortBy=${sortParam}&order=${orderParam}&page=${page}&pageSize=${pageSize}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<PaginatedServiceProviderResult>(queryUrl, requestParams);
    }

    async createNewServiceProvider(serviceProvider: ServiceProvider): Promise<string> {

        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/new`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(serviceProvider),
        };
        const result = await this.executeRequest<ServiceProviderCreateResponse>(queryUrl, requestParams);
        return result.key;
    }

    async updateServiceProvider(serviceProvider: ServiceProvider): Promise<ServiceProvider | null> {
        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/update`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(serviceProvider),
        };
        const response: ServiceProviderResponse | null = await this.executeRequestWithNullableResult<ServiceProviderResponse>(queryUrl, requestParams);
        if (response) {
            return ServiceProvidersRepositoryImpl.mapToServiceProvider(response);
        }
        return null;
    }

    async deleteServiceProvider(): Promise<boolean> {
        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/delete`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
            credentials: 'include',
        };

        return this.executeRequest<boolean>(queryUrl, requestParams);
    }

    async getServiceProviderByUserKey(): Promise<ServiceProvider | null> {

        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/getByUserKey`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        };

        const response: ServiceProviderResponse | null = await this.executeRequestWithNullableResult<ServiceProviderResponse>(queryUrl, requestParams);

        if (response) {
            return ServiceProvidersRepositoryImpl.mapToServiceProvider(response);
        }
        return null;
    }

    async getServiceProviderByKey(serviceProviderKey: string): Promise<ServiceProvider | null> {
        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/${serviceProviderKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        };

        const response: ServiceProviderResponse | null = await this.executeRequestWithNullableResult<ServiceProviderResponse>(queryUrl, requestParams);

        if (response) {
            return ServiceProvidersRepositoryImpl.mapToServiceProvider(response);
        }
        return null;
    }

    async getAvailableTrades(): Promise<Trade[] | null> {
        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/trades`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        };

        const response: TradeResponse = await this.executeRequest<TradeResponse>(queryUrl, requestParams);

        return ServiceProvidersRepositoryImpl.mapToTrades(response);
    }

    async getServiceProviderNameByUserKey(userKey: string): Promise<string | null> {
        const queryUrl = `${Config.backendBaseUrl}/api/service-providers/name/${userKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            credentials: 'include',
        };
        const result: ServiceProviderNameResponse | null = await this.executeRequestWithNullableResult<ServiceProviderNameResponse>(queryUrl, requestParams);

        if (result) {
            return result.value;
        }
        return null;
    }

    private async executeRequestWithNullableResult<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType | null> {
        try {
            const response = await fetch(requestUrl, requestParams);
            if (response.status === 204) {
                return null;
            }
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);

            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private mapSortByToUrlParameterValue(sortBy: SortBy): string {
        switch (sortBy) {
            case SortBy.PLACE:
                return 'PLACE';
            case SortBy.OFFERED_SERVICES:
                return 'OFFERED_SERVICES';
            case SortBy.TRADES:
                return 'TRADES';
            default:
                return 'NAME';
        }
    }

    private mapSortOrderToUrlParameterValue(order: SortDirection): string {
        switch (order) {
            case SortDirection.ASCENDING:
                return 'ASC';
            case SortDirection.DESCENDING:
                return 'DESC';
        }
    }

    private static mapToServiceProvider(serviceProviderResponse: ServiceProviderResponse): ServiceProvider {
        return {
            key: serviceProviderResponse.key,
            userKey: serviceProviderResponse.userKey,
            name: serviceProviderResponse.name,
            openingTimes: serviceProviderResponse.openingTimes,
            address: serviceProviderResponse.address,
            tradeKeys: serviceProviderResponse.tradeKeys,
            additionalInformation: serviceProviderResponse.additionalInformation,
        }
    }

    private static mapToTrades(tradeResponse: TradeResponse): Trade[] {
        const result: Trade[] = [];

        for (let i=0; i<tradeResponse.trades.length; i++) {
            result[i] = {
                key: tradeResponse.trades[i].key.value,
                name: tradeResponse.trades[i].name.value,
            }
        }
        return result;
    }
}
