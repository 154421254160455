import {ChangePasswordRequest} from '@/user/model/changePasswordRequest';
import UserProfileUpdateRequest from '@/user/model/userProfileUpdateRequest';
import UserRepository from '@/user/repository/userRepository';
import User from "@/user/model/User";
import UserInfo from "@/user/model/UserInfo";
import {DashboardConfiguration} from "@/dashboard/model/DashboardConfiguration";
import DashboardCardType from "@/dashboard/model/DashboardCardType";
// import UserRole from "@/user/model/UserRole";

export default class UserService {
    private userRepository: UserRepository;

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository;
    }

    async getAuthenticatedUser(): Promise<User | null> {
        return await this.userRepository.getUser();
    }

    async getUserByKey(userKey: string): Promise<User | null> {
        return this.userRepository.getUserByKey(userKey);
    }

    async getUserInfo(): Promise<UserInfo | null> {
        return this.userRepository.getUserInfo();
    }

    async getUserInfoByKey(userKey: string): Promise<UserInfo | null> {
        return this.userRepository.getUserInfoByKey(userKey);
    }

    async updateUserInfo(userInfo: UserInfo): Promise<UserInfo> {
        return this.userRepository.updateUserInfo(userInfo);
    }

    async deleteUserInfo(): Promise<boolean> {
        return this.userRepository.deleteUserInfo();
    }

    async updateUserProfile(newUserData: UserProfileUpdateRequest): Promise<string[]> {
        return this.userRepository.updateUserProfile(newUserData);
    }

    async deleteUser(): Promise<boolean> {
        return this.userRepository.deleteUser();
    }

    async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<string[]> {
        return this.userRepository.changePassword(changePasswordRequest);
    }

    async sendVerificationEmail(): Promise<boolean> {
        return this.userRepository.sendVerificationEmail();
    }

    async performBasicAuth(username: string, password: string): Promise<boolean> {
        return this.userRepository.performBasicAuth(username, password);
    }

    async getDashboardConfiguration(): Promise<DashboardConfiguration[] | null> {
        return this.userRepository.getDashboardConfiguration();
    }

    async updateDashboardConfiguration(dashboardConfiguration: DashboardConfiguration[]): Promise<boolean> {
        return this.userRepository.updateDashboardConfiguration(dashboardConfiguration);
    }

    async automaticDashboardConfigurationOnPropertyCreation(newPropertyKey: string): Promise<boolean> {
        // update dashboard configuration on property creation, add two cards for the new property
        const dashboardConfiguration = await this.getDashboardConfiguration();
        if (dashboardConfiguration && dashboardConfiguration.length < 12) {
            dashboardConfiguration.push(
                {
                    propertyKey: newPropertyKey,
                    type: DashboardCardType.ELECTRICITY,
                    displayTable: false,
                    userKey: dashboardConfiguration[0].userKey,
                    sequence: dashboardConfiguration[dashboardConfiguration.length - 1].sequence + 1
                },
                {
                    propertyKey: newPropertyKey,
                    type: DashboardCardType.GAS,
                    displayTable: false,
                    userKey: dashboardConfiguration[0].userKey,
                    sequence: dashboardConfiguration[dashboardConfiguration.length - 1].sequence + 2
                })
            return this.updateDashboardConfiguration(dashboardConfiguration);
        }
        return false;
    }

    deleteDashboardCardsByPropertyKey(propertyKey: string): Promise<boolean> {
        return this.userRepository.deleteDashboardCardsByPropertyKey(propertyKey);
    }

    deleteDashboardConfiguration(): Promise<boolean> {
        return this.userRepository.deleteDashboardConfiguration();
    }
}
