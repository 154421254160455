import MessagesService from '@/messaging/messagesService';
import {Conversation} from '@/messaging/model/conversation';
import {ConversationOverview} from '@/messaging/model/conversationOverview';
import {CustomFolder} from '@/messaging/model/customFolder';
import {MessageFilter} from '@/messaging/model/messageFilter';
import {Message} from "@/messaging/model/message";

export default class MessagesPort {

    private messagesService: MessagesService;


    constructor(messagesService: MessagesService) {
        this.messagesService = messagesService;
    }

    public getFolders(): Promise<CustomFolder[]> {
        return this.messagesService.getFolders();
    }

    public async getConversationOverviews(messageFilter: MessageFilter, folderKey = ''): Promise<ConversationOverview[]> {
        return this.messagesService.getConversationOverviews(messageFilter, folderKey);
    }

    public async getSingleConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<Conversation> {
        return this.messagesService.getSingleConversation(conversationKey, messageFilter, folderKey);
    }

    public async sendMessage(message: Message): Promise<boolean> {
        return this.messagesService.sendMessage(message);
    }

    public async moveMessage(messageKey: string, messageFilter: MessageFilter): Promise<boolean> {
        return this.messagesService.moveMessage(messageKey, messageFilter);
    }

    public async moveConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string, currentFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {
        return this.messagesService.moveConversation(conversationKey, messageFilter, folderKey, currentFilter, currentFolderKey);
    }

    public async deleteMessage(messageKey: string): Promise<boolean> {
        return this.messagesService.deleteMessage(messageKey);
    }

    public async deleteConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<boolean> {
        return this.messagesService.deleteConversation(conversationKey, messageFilter, folderKey);
    }

    public async unmarkMessage(messageKey: string): Promise<boolean> {
        return this.messagesService.unmarkMessage(messageKey);
    }

    public async unmarkConversation(conversationKey: string, currentMessageFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {
        return this.messagesService.unmarkConversation(conversationKey, currentMessageFilter, currentFolderKey);
    }

    public async restoreMessage(messageKey: string): Promise<boolean> {
        return this.messagesService.restoreMessage(messageKey);
    }

    public async restoreConversation(conversationKey: string): Promise<boolean> {
        return this.messagesService.restoreConversation(conversationKey);
    }

    createCustomFolder(folderName: string): Promise<boolean> {
        return this.messagesService.createCustomFolder(folderName);
    }

    deleteCustomFolder(folderKey: string): Promise<boolean> {
        return this.messagesService.deleteCustomFolder(folderKey);
    }
}
