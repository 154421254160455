import {createFundingProgramsPort} from '@/fundingPrograms';
import {createMeasuresPort} from '@/measures';
import {createMessagesPort} from '@/messaging';
import {createPropertiesPort} from '@/properties';
import {createServiceProvidersPort} from '@/serviceProviders';
import { createApp, watch } from 'vue'
import {directive as ClickAwayDirective} from 'vue3-click-away';
import App from './App.vue'
import { createNavigationPort } from '@/navigation';
import { createUserPort } from "@/user";
import {Router} from "vue-router";
import {createQuickcheckPort} from "@/quickcheck";
import { userSettingsService } from '@/stores/userSettings'

import VueApexCharts from "vue3-apexcharts";

import 'tw-elements';
import { createPinia } from "pinia"
import vue3dLoader from "vue-3d-loader";

// Create port list
const navigationPort = createNavigationPort();
const userPort = createUserPort();
const quickcheckPort = createQuickcheckPort();
const propertiesPort = createPropertiesPort();
const measuresPort = createMeasuresPort();
const serviceProvidersPort = createServiceProvidersPort();
const fundingProgramsPort = createFundingProgramsPort();
const messagesPort = createMessagesPort();
const $ports = {
    nav: navigationPort,
    user: userPort,
    quickcheck: quickcheckPort,
    properties: propertiesPort,
    measures: measuresPort,
    serviceProviders: serviceProvidersPort,
    fundingPrograms: fundingProgramsPort,
    messages: messagesPort,
};

// Pinia init
const pinia = createPinia()

// App init
const app = createApp(App);

// Port binding
app.config.globalProperties.$ports = $ports;
app.provide('$ports', $ports);
app.directive('clickAway', ClickAwayDirective)
app.use(vue3dLoader)

// Pinia binding
app.use(pinia)

// Pinia -> userSettings and watcher for localStorage update
const userSettings = userSettingsService()
userSettings.loadUserSettings();

watch(userSettings, () => {
    userSettings.updateUserSettings()
  },
  { deep: true }
);


// App mounting
app.use(VueApexCharts).use(<Router>navigationPort.getRouter()).mount('#app');