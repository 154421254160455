// import {MockServiceProvidersRepository} from '@/serviceProviders/repository/mockServiceProvidersRepository';
import {ServiceProvidersRepositoryImpl} from '@/serviceProviders/repository/serviceProvidersRepositoryImpl';
import {ServiceProvidersPort} from '@/serviceProviders/serviceProvidersPort';
import {ServiceProvidersService} from '@/serviceProviders/serviceProvidersService';

function createServiceProvidersPort(): ServiceProvidersPort {
    return new ServiceProvidersPort(new ServiceProvidersService(new ServiceProvidersRepositoryImpl()));
}

export {createServiceProvidersPort};
