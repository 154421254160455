import {defineStore} from 'pinia'

export const userInfoStore = defineStore('userInfo', {
    state() {
        return {
            role: '',
        }
    },
    getters: {
        getRole: (state): string => {
            return state.role;
        }
    },
    actions: {
        updateUserRole(newRole: string) {
            this.role = newRole;
        },
        clearRole() {
            this.$reset();
        },
    }
});