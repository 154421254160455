import SortDirection from '@/common/table/types/sortDirection';
import {PaginatedServiceProviderResult} from '@/serviceProviders/model/paginatedServiceProviderResult';
import SortBy from '@/serviceProviders/model/sortBy';
import ServiceProvider from "@/serviceProviders/model/ServiceProvider";
import {ServiceProvidersRepositoryImpl} from "@/serviceProviders/repository/serviceProvidersRepositoryImpl";
import {Trade} from "@/serviceProviders/model/trade";

export class ServiceProvidersService {

    private serviceProvidersRepository: ServiceProvidersRepositoryImpl;


    constructor(serviceProvidersRepository: ServiceProvidersRepositoryImpl) {
        this.serviceProvidersRepository = serviceProvidersRepository;
    }

    registerServiceProvider(serviceProvider: ServiceProvider): Promise<string> {
        return this.serviceProvidersRepository.createNewServiceProvider(serviceProvider);
    }

    updateServiceProvider(serviceProvider: ServiceProvider): Promise<ServiceProvider | null> {
        return this.serviceProvidersRepository.updateServiceProvider(serviceProvider);
    }

    deleteServiceProvider(): Promise<boolean> {
        return this.serviceProvidersRepository.deleteServiceProvider();
    }

    getServiceProviderByUserKey(): Promise<ServiceProvider | null> {
        return this.serviceProvidersRepository.getServiceProviderByUserKey();
    }

    getServiceProviderByKey(serviceProviderKey: string): Promise<ServiceProvider | null> {
        return this.serviceProvidersRepository.getServiceProviderByKey(serviceProviderKey);
    }

    getServiceProviderNameByUserKey(userKey: string): Promise<string | null> {
        return this.serviceProvidersRepository.getServiceProviderNameByUserKey(userKey);
    }

    getAvailableTrades(): Promise<Trade[] | null> {
        return this.serviceProvidersRepository.getAvailableTrades();
    }

    // getAvailableMeasures(): Promise<Measure[]> {
    //     return this.serviceProvidersRepository.getAvailableMeasures();
    // }

    public searchServiceProviders(searchTerm: string,
                                  measureFilterKey: string,
                                  sortBy: SortBy,
                                  sortDirection: SortDirection,
                                  page: number,
                                  pageSize: number): Promise<PaginatedServiceProviderResult> {

        return this.serviceProvidersRepository.searchServiceProviders(searchTerm, measureFilterKey, sortBy, sortDirection, page, pageSize);
    }
}
