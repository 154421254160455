import {ChangePasswordRequest} from '@/user/model/changePasswordRequest';
import User from "@/user/model/User";
import Config from "@/config";
import UserInfo from "@/user/model/UserInfo";
import UserProfileUpdateRequest from '@/user/model/userProfileUpdateRequest';
import {DashboardConfiguration} from "@/dashboard/model/DashboardConfiguration";

export default class UserRepository {

    private userBackendUrl = `${Config.backendBaseUrl}/api/user`;

    async getUser(): Promise<User | null> {
        return fetch(`${Config.backendBaseUrl}/api/user/authuser`, {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include'
        })
        .then((response) => {
            if (response.status === 401) {
                return null;
            }
            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        }).catch((e) => {
            throw e;
        });
    }

    async getUserByKey(userKey: string): Promise<User | null> {
        return fetch(`${Config.backendBaseUrl}/api/user/${userKey}`, {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include'
        })
        .then((response) => {
            if (response.status === 401) {
                return null;
            }
            if (response.status === 204) {
                return null;
            }
            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        }).catch((e) => {
            throw e;
        });
    }

    async getUserInfo(): Promise<UserInfo | null> {
        return fetch(`${Config.backendBaseUrl}/api/user/userinfo`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include',
        }).then((response) => {
            if (response.status === 401) {
                return null;
            }
            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        }).catch((e) => {
            throw e;
        });
    }

    async getUserInfoByKey(userKey: string): Promise<UserInfo | null> {
        return fetch(`${Config.backendBaseUrl}/api/user/userinfo/${userKey}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include',
        }).then((response) => {
            if (response.status === 401) {
                return null;
            }
            if (response.status === 204) {
                return null;
            }
            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        }).catch((e) => {
            throw e;
        });
    }

    async updateUserInfo(userInfo: UserInfo): Promise<UserInfo> {
        return fetch(`${Config.backendBaseUrl}/api/user/userinfo/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            body: JSON.stringify(userInfo),
            credentials: 'include'
        }).then((response) => {
            if (response.status === 401) {
                return null;
            }
            if (response.status !== 200) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        }).catch((e) => {
            throw e;
        });
    }

    async deleteUserInfo(): Promise<boolean> {
        const requestUrl = `${this.userBackendUrl}/userinfo`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async updateUserProfile(newUserData: UserProfileUpdateRequest): Promise<string[]> {
        return fetch(`${Config.backendBaseUrl}/api/user/userprofile`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            body: JSON.stringify(newUserData),
            credentials: 'include'
        }).then(async (response) => {
            if (response.status === 401) {
                // login in if not authenticated
                window.location.replace(Config.loginUrl);
            } else if (response.status >= 300) {
                const json = await response.json();
                return json['errors'];
            }
            return [];
        }).catch((e) => {
            throw e;
        });
    }

    async sendVerificationEmail(): Promise<boolean> {
        return fetch(`${Config.backendBaseUrl}/api/user/email/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include'
        }).then(async (response) => {
           return response.status < 300;
        }).catch((e) => {
            throw e;
        });
    }

    async deleteUser(): Promise<boolean> {
        return fetch(`${Config.backendBaseUrl}/api/user/userprofile`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            credentials: 'include'
        }).then(async (response) => {
            if (response.status === 401) {
                // login in if not authenticated
                window.location.replace(Config.loginUrl);
            }
            return response.status < 300;

        }).catch((e) => {
            throw e;
        });
    }

    async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<string[]> {
        return fetch(`${Config.backendBaseUrl}/api/user/password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            body: JSON.stringify(changePasswordRequest),
            credentials: 'include'
        }).then(async (response) => {
            if (response.status === 401) {
                // login in if not authenticated
                window.location.replace(Config.loginUrl);
            }

            if (response.status >= 300) {
                const json = await response.json();
                return json['errors'];
            }
            return [];

        }).catch((e) => {
            throw e;
        });
    }

    async performBasicAuth(username: string, password: string): Promise<boolean> {
        const base64Token = btoa(username + ':' + password);

        return fetch(`${Config.backendBaseUrl}/api/user/basic-auth`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': base64Token,
            },
            credentials: 'include'
        }).then(async (response) => {
            const success: boolean = response.status === 200;

            if (success) {
                Config.setCookie(Config.basicAuthCookieName, base64Token);
            }

            return success
        }).catch((e) => {
            throw e;
        });
    }

    async getDashboardConfiguration(): Promise<DashboardConfiguration[] | null> {
        const requestUrl = `${this.userBackendUrl}/getDashboardConfiguration`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<DashboardConfiguration[] | null>(requestUrl, requestParams);
    }

    async updateDashboardConfiguration(dashboardConfigurations: DashboardConfiguration[]): Promise<boolean> {
        const requestUrl = `${this.userBackendUrl}/updateDashboardConfiguration`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(dashboardConfigurations),
            method: 'PUT',
            credentials: 'include'
        };
        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    deleteDashboardCardsByPropertyKey(propertyKey: string): Promise<boolean> {
        const requestUrl = `${this.userBackendUrl}/deleteDashboardCardsByProperty?propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };
        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    deleteDashboardConfiguration(): Promise<boolean> {
        const requestUrl = `${this.userBackendUrl}/deleteDashboardConfiguration`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };
        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);

            if (response.status === 401) {
                // login in if not authenticated
                window.location.replace(Config.loginUrl);
            }
            return response.ok ? response.json() : Promise.reject(false);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async executeRequestWithNullableResult<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType | null> {
        try {
            const response = await fetch(requestUrl, requestParams);
            if (response.status === 204) {
                return null;
            }
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
