import { defineStore } from 'pinia'
import { buildingService } from '@/properties/views/components/PropertyRegistration/stores/buildingData'

export enum propertyCategories {
  All,
  Basics,
  Walls,
  Floor,
  Windows,
  Roof,
  Technology
}

const progressDefaultState = {
  All: 0,
  Basics: 0,
  Walls: 0,
  Floor: 0,
  Windows: 0,
  Roof: 0,
  Technology: 0
}

export const progressService = defineStore('progress', {
  state() {
    return {
      ...progressDefaultState
    }
  },
  actions: {
    reset() {
      Object.assign(this, progressDefaultState);
    },
    // Kalkulation des Fortschrittes, ungewichtet anhand nicht gefüllter Felder im Objekt
    // Um die Gewichtung einzubauen muss diese Funktion angepasst werden
    async calc(categorieNumber: number) {
      const categories: any[] = [];
      const building = buildingService();

      switch(categorieNumber) {
        case propertyCategories.Basics: categories[0] = [building.detailedProperty.basic]; break;
        case propertyCategories.Walls: categories[0] = [building.detailedProperty.wallWrapper]; break;
        case propertyCategories.Floor: categories[0] = [building.detailedProperty.floorWrapper]; break;
        case propertyCategories.Windows: categories[0] = [building.detailedProperty.windowWrapper]; break;
        case propertyCategories.Roof: categories[0] = [building.detailedProperty.roofWrapper]; break;
        case propertyCategories.Technology: categories[0] = [building.detailedProperty.technology]; break;
        default:
          categories.push([building.detailedProperty.basic]);
          categories.push([building.detailedProperty.wallWrapper]);
          categories.push([building.detailedProperty.floorWrapper]);
          categories.push([building.detailedProperty.windowWrapper]);
          categories.push([building.detailedProperty.roofWrapper]);
          categories.push([building.detailedProperty.technology]);
      }

      let fieldcount = 0;
      let filled = 0;
      let currentCategory = 0;

      categories.forEach((categorie: any[]) => {
        fieldcount = 0;
        filled = 0;

        if(categorieNumber !== propertyCategories.All)
          currentCategory = categorieNumber;
        else
          currentCategory++;

        while (categorie?.length > 0) {
          const currentObj: any = categorie.pop();
          //Scan object
          if (currentObj === null) {
            continue;
          }
          Object.keys(currentObj).forEach(key => {
            if (typeof(currentObj[key]) === 'object' && currentObj[key] !== null) {
              categorie.push(currentObj[key]);
            } else {
              if(key !== "buildYear" && key !== 'energyClass' && key !== 'isDeleted' && key !== 'fullFloors') {
                if(currentObj[key] !== null && currentObj[key] !== 0 && currentObj[key] !== '')
                  filled++;

                fieldcount++;
              }
            }
          });
        }

        // Calc percent
        switch(currentCategory) {
          case propertyCategories.Basics: this.$state.Basics = Math.round((filled / fieldcount) * 100); break;
          case propertyCategories.Walls: this.$state.Walls = Math.round((filled / fieldcount) * 100); break;
          case propertyCategories.Floor: this.$state.Floor = Math.round((filled / fieldcount) * 100); break;
          case propertyCategories.Windows: this.$state.Windows = Math.round((filled / fieldcount) * 100); break;
          case propertyCategories.Roof: this.$state.Roof = Math.round((filled / fieldcount) * 100); break;
          case propertyCategories.Technology: this.$state.Technology = Math.round((filled / fieldcount) * 100); break;
        }
      });

      this.$state.All =
        Math.round(
          (
            (
              this.$state.Basics +
              this.$state.Walls +
              this.$state.Floor +
              this.$state.Windows +
              this.$state.Roof +
              this.$state.Technology
            ) / 600
          ) * 100);
    }
  }
});