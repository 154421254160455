import SortDirection from '@/common/table/types/sortDirection';
import Config from '@/config';
import {PaginatedFundingProgramResult} from '@/fundingPrograms/model/paginatedFundingProgramResult';
import FundingProgramsRepository from '@/fundingPrograms/repository/fundingProgramsRepository';
import SortBy from '@/fundingPrograms/model/sortBy';

export class FundingProgramsRepositoryImpl implements FundingProgramsRepository {

    private measuresBackendUrl = `${Config.backendBaseUrl}/api/funding-programs`;

    async searchFundingPrograms(searchTerm: string,
                                measureFilter: string,
                                propertyFilter: string,
                                sortBy: SortBy,
                                sortDirection: SortDirection,
                                page: number,
                                pageSize: number): Promise<PaginatedFundingProgramResult> {

        const sortParam = this.mapSortByToUrlParameterValue(sortBy);
        const orderParam = this.mapSortOrderToUrlParameterValue(sortDirection);
        const queryUrl = `${this.measuresBackendUrl}/search?searchTerm=${searchTerm}&measureFilter=${measureFilter}&propertyFilter=${propertyFilter}&sortBy=${sortParam}&order=${orderParam}&page=${page}&pageSize=${pageSize}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<PaginatedFundingProgramResult>(queryUrl, requestParams);
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private mapSortByToUrlParameterValue(sortBy: SortBy): string {
        switch (sortBy) {
            case SortBy.DURATION:
                return 'DURATION';
            case SortBy.HEIGHT_OF_FUNDING:
                return 'HEIGHT_OF_FUNDING';
            default:
                return 'NAME';
        }
    }

    private mapSortOrderToUrlParameterValue(order: SortDirection): string {
        switch (order) {
            case SortDirection.ASCENDING:
                return 'ASC';
            case SortDirection.DESCENDING:
                return 'DESC';

        }
    }
}
