import {ChangePasswordRequest} from '@/user/model/changePasswordRequest';
import UserProfileUpdateRequest from '@/user/model/userProfileUpdateRequest';
import UserService from '@/user/userService';
import User from '@/user/model/User';
import UserInfo from '@/user/model/UserInfo';
import {DashboardConfiguration} from "@/dashboard/model/DashboardConfiguration";

export default class UserPort {
    private userService: UserService;

    constructor(userService: UserService) {
        this.userService = userService;
    }

    async getAuthenticatedUser(): Promise<User | null> {
        return this.userService.getAuthenticatedUser();
    }

    async getUserByKey(userKey: string): Promise<User | null> {
        return this.userService.getUserByKey(userKey);
    }

    async getUserInfo(): Promise<UserInfo | null> {
        return this.userService.getUserInfo();
    }

    async getUserInfoByKey(userKey: string): Promise<UserInfo | null> {
        return this.userService.getUserInfoByKey(userKey);
    }

    async updateUserInfo(userInfo: UserInfo): Promise<UserInfo> {
        return this.userService.updateUserInfo(userInfo);
    }

    async deleteUserInfo(): Promise<boolean> {
        return this.userService.deleteUserInfo();
    }

    async updateUserProfile(newUserData: UserProfileUpdateRequest): Promise<string[]> {
        return this.userService.updateUserProfile(newUserData);
    }

    async deleteUser(): Promise<boolean> {
        return this.userService.deleteUser();
    }

    async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<string[]> {
        return this.userService.changePassword(changePasswordRequest);
    }

    async sendVerificationEmail(): Promise<boolean> {
        return this.userService.sendVerificationEmail();
    }

    async performBasicAuth(username: string, password: string): Promise<boolean> {
        return this.userService.performBasicAuth(username, password);
    }

    async getDashboardConfiguration(): Promise<DashboardConfiguration[] | null> {
        return this.userService.getDashboardConfiguration();
    }

    async updateDashboardConfiguration(dashboardConfiguration: DashboardConfiguration[]): Promise<boolean> {
        return this.userService.updateDashboardConfiguration(dashboardConfiguration);
    }

    async automaticDashboardConfigurationOnPropertyCreation(newPropertyKey: string): Promise<boolean> {
        return this.userService.automaticDashboardConfigurationOnPropertyCreation(newPropertyKey);
    }

    deleteDashboardCardsByPropertyKey(propertyKey: string): Promise<boolean> {
        return this.userService.deleteDashboardCardsByPropertyKey(propertyKey);
    }

    deleteDashboardConfiguration(): Promise<boolean> {
        return this.userService.deleteDashboardConfiguration();
    }
}
