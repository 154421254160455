import {defineStore} from 'pinia'
import {Option} from '@/common/select/types/CustomSelectOption';

// Properties of type Option are alter through control-binding
interface userSettings {
    dashboard: {
        widgetID: number[]            // ID der genutzten Widgets, Reihenfolge in Array definiert Positionierung im Dialog
    },
    fundingPrograms: {
        pagination: Option
    },
    measures: {
        pagination: Option
    },
    messages: {
        pagination: Option
    },
    properties: {
        pagination: Option
    },
    serviceProviders: {
        pagination: Option
    },
    sharedProperties: {
        pagination: Option
    },
    user: {
        isDeleted: boolean
    },
    detailedProperty: {
        stdCamera: string
    }
}

export const userSettingsService = defineStore('userSettings', {
    state: ():
        userSettings => ({
        dashboard: {
            widgetID: []
        },
        fundingPrograms: {
            pagination: {
                name: '10', value: 10
            }
        },
        measures: {
            pagination: {
                name: '10', value: 10
            }
        },
        messages: {
            pagination: {
                name: '10', value: 10
            }
        },
        properties: {
            pagination: {
                name: '10', value: 10
            }
        },
        serviceProviders: {
            pagination: {
                name: '10', value: 10
            }
        },
        sharedProperties: {
            pagination: {
                name: '10', value: 10
            }
        },
        user: {
            isDeleted: false,
        },
        detailedProperty: {
            stdCamera: ''
        }
    }),
    getters: {
        getUserDeleted: (state): boolean => {
            return state.user.isDeleted;
        },
    },
    actions: {
        updateUserSettings() {
            localStorage.setItem('userSettingsState', JSON.stringify(this.$state));
        },
        // Called once on app init
        async loadUserSettings() {
            const storedData = localStorage.getItem('userSettingsState')
            console.log('Loading userSettings...')

            if (storedData) {
                const storedDataParsed = JSON.parse(storedData)

                // On null value -> use default
                // Merging changes in interface, default values of the state and the localStorage
                this.$state.dashboard.widgetID = storedDataParsed.dashboard.widgetID ? storedDataParsed.dashboard.widgetID : this.$state.dashboard.widgetID
                this.$state.fundingPrograms.pagination = storedDataParsed.fundingPrograms.pagination ? storedDataParsed.fundingPrograms.pagination : this.$state.fundingPrograms.pagination
                this.$state.measures.pagination = storedDataParsed.measures.pagination ? storedDataParsed.measures.pagination : this.$state.measures.pagination
                this.$state.messages.pagination = storedDataParsed.messages.pagination ? storedDataParsed.messages.pagination : this.$state.messages.pagination
                this.$state.properties.pagination = storedDataParsed.properties.pagination ? storedDataParsed.properties.pagination : this.$state.properties.pagination
                this.$state.serviceProviders.pagination = storedDataParsed.serviceProviders.pagination ? storedDataParsed.serviceProviders.pagination : this.$state.serviceProviders.pagination
                this.$state.user.isDeleted = storedDataParsed.user.isDeleted ? storedDataParsed.user.isDeleted : this.$state.user.isDeleted
                this.$state.detailedProperty.stdCamera = storedDataParsed.detailedProperty.stdCamera ? storedDataParsed.detailedProperty.stdCamera : this.$state.detailedProperty.stdCamera

                console.log('Loading userSettings complete')
            } else {
                console.log('No userSettings found')
            }

            return true;
        },
        updateWidgetID(widgets: number[]) {
            this.$state.dashboard.widgetID = widgets;
        },
        updateCamera(cameraName: string) {
            this.$state.detailedProperty.stdCamera = cameraName;
        },
        updateUserDeletion(newState: boolean) {
            this.$state.user.isDeleted = newState;
        }
    }
});