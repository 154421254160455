import {defineStore} from 'pinia'
import {MessageFilter} from "@/messaging/model/messageFilter";
import {CustomFolder} from '@/messaging/model/customFolder';

export const messagesStore = defineStore('messages', {
    state() {
        return {
            isNewMessage: false,
            recipient: '',
            receivingUserKey: '',
            conversationKey: '',
            currentFilter: MessageFilter.INBOX,
            currentFolderFilterID: '',
            currentFolderName: '',
            nextFilter: MessageFilter.NONE,
            nextFolderFilterID: '',
            nextFolderName: '',
            customFolders: [] as CustomFolder[]
        }
    },
    getters: {
        getIsNewMessage: (state): boolean => {
            return state.isNewMessage;
        },
        getRecipient: (state): string => {
            return state.recipient;
        },
        getReceivingUserKey: (state): string => {
            return state.receivingUserKey;
        },
        getConversationKey: (state): string => {
            return state.conversationKey;
        },
        getCurrentFilter: (state): MessageFilter => {
            return state.currentFilter;
        },
        getCurrentFolderFilterID: (state): string => {
            return state.currentFolderFilterID;
        },
        getCurrentFolderName: (state): string => {
            return state.currentFolderName;
        },
        getNextFilter: (state): MessageFilter => {
            return state.nextFilter;
        },
        getNextFolderFilterID: (state): string => {
            return state.nextFolderFilterID;
        },
        getNextFolderName: (state): string => {
            return state.nextFolderName;
        },
        getCustomFolders: (state): CustomFolder[] => {
            return state.customFolders;
        }
    },
    actions: {
        updateIsNewMessage(newState: boolean) {
            this.isNewMessage = newState;
        },
        updateRecipient(newRecipient: string) {
            this.recipient = newRecipient;
        },
        updateReceivingUserKey(newUserKey: string) {
            this.receivingUserKey = newUserKey;
        },
        updateConversationKey(newConversationKey: string) {
            this.conversationKey = newConversationKey;
        },
        setNextFilter(newNextFilter: MessageFilter) {
            this.nextFilter = newNextFilter;
        },
        setNextFolderFilterID(newFolderFilterID: string) {
            this.nextFolderFilterID = newFolderFilterID
        },
        setNextFolderName(newFolderName: string) {
            this.nextFolderName = newFolderName;
        },
        setCurrentFilter(newFilter: MessageFilter) {
            this.currentFilter = newFilter;
        },
        setCurrentFolderFilterID(newFolderID: string) {
            this.currentFolderFilterID = newFolderID;
        },
        setCurrentFolderName(newFolderName: string) {
            this.currentFolderName = newFolderName;
        },
        setCustomFolders(newCustomFolders: CustomFolder[]) {
            this.customFolders = newCustomFolders;
        },
        displayNewFolderDialog() {
            console.log('display new folder dialog');
        },
        handleFolderDelete() {
            console.log('handleFolderDelete');
        },
        clearMessageStatus() {
            this.isNewMessage = false;
            this.recipient = '';
            this.receivingUserKey = '';
            this.conversationKey = '';
        }
    }
});