import SortDirection from '@/common/table/types/sortDirection';
import {FundingProgramsService} from '@/fundingPrograms/fundingProgramsService';
import {PaginatedFundingProgramResult} from '@/fundingPrograms/model/paginatedFundingProgramResult';
import SortBy from '@/fundingPrograms/model/sortBy';

export class FundingProgramsPort {

    private fundingProgramsService: FundingProgramsService;


    constructor(fundingProgramsService: FundingProgramsService) {
        this.fundingProgramsService = fundingProgramsService;
    }

    public async searchFundingPrograms(searchTerm: string,
                                       measureFilter: string,
                                       propertyFilter: string,
                                       sortBy: SortBy,
                                       sortDirection: SortDirection,
                                       page: number,
                                       pageSize: number): Promise<PaginatedFundingProgramResult> {

        return this.fundingProgramsService.searchFundingPrograms(searchTerm, measureFilter, propertyFilter, sortBy, sortDirection, page, pageSize);
    }
}
