import {Conversation} from '@/messaging/model/conversation';
import {ConversationOverview} from '@/messaging/model/conversationOverview';
import {CustomFolder} from '@/messaging/model/customFolder';
import {MessageFilter} from '@/messaging/model/messageFilter';
import {MessageRepository} from '@/messaging/repository/messageRepository';
import {Message} from "@/messaging/model/message";

export default class MessagesService {

    private messageRepository: MessageRepository;

    constructor(messageRepository: MessageRepository) {
        this.messageRepository = messageRepository;
    }

    public async getFolders(): Promise<CustomFolder[]> {
        return this.messageRepository.getFolders();
    }

    public async getConversationOverviews(messageFilter: MessageFilter, folderKey: string): Promise<ConversationOverview[]> {
        return this.messageRepository.getConversationOverviews(messageFilter, folderKey);
    }

    public async getSingleConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<Conversation> {
        return this.messageRepository.getSingleConversation(conversationKey, messageFilter, folderKey);
    }

    public async sendMessage(message: Message): Promise<boolean> {
        return this.messageRepository.sendMessage(message);
    }

    public async moveMessage(messageKey: string, messageFilter: MessageFilter): Promise<boolean> {
        return this.messageRepository.moveMessage(messageKey, messageFilter);
    }

    public async moveConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string, currentFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {
        return this.messageRepository.moveConversation(conversationKey, messageFilter, folderKey, currentFilter, currentFolderKey);
    }

    public async deleteMessage(messageKey: string): Promise<boolean> {
        return this.messageRepository.deleteMessage(messageKey);
    }

    public async deleteConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<boolean> {
        return this.messageRepository.deleteConversation(conversationKey, messageFilter, folderKey);
    }

    public async unmarkMessage(messageKey: string): Promise<boolean> {
        return this.messageRepository.unmarkMessage(messageKey);
    }

    public async unmarkConversation(conversationKey: string, currentMessageFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {
        return this.messageRepository.unmarkConversation(conversationKey, currentMessageFilter, currentFolderKey);
    }

    public async restoreMessage(messageKey: string): Promise<boolean> {
        return this.messageRepository.restoreMessage(messageKey);
    }

    public async restoreConversation(conversationKey: string): Promise<boolean> {
        return this.messageRepository.restoreConversation(conversationKey);
    }

    createCustomFolder(folderName: string): Promise<boolean> {
        return this.messageRepository.createCustomFolder(folderName);
    }

    deleteCustomFolder(folderKey: string): Promise<boolean> {
        return this.messageRepository.deleteCustomFolder(folderKey);
    }
}
