import {defineStore} from "pinia";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";

export const estimationService = defineStore('estimationResult', {
    state: ():
        DetailedEstimation => ({
        ...estimationDefaultState
    }),

    actions: {
        updateCurrentEstimation(estimation: DetailedEstimation) {
            this.$state = estimation;
        },
        reset() {
            this.$reset();
        },
    },
    getters: {
        getTotalHeatLossPerArea(state): number {
            return state.totalEstimation.heatLossPerBasicArea;
        },
        getTotalErrorHeatLossPerArea(state): number {
            return state.totalEstimation.errorHeatLossPerBasicArea;
        },
        getTotalEstimation(state) {
            return state.totalEstimation;
        },
        getWallEstimations(state) {
            return state.wallEstimations;
        },
        getFloorEstimations(state) {
            return state.floorEstimations;
        },
        getDoorEstimations(state) {
            return state.doorEstimations;
        },
        getOuterWindowEstimations(state) {
            return state.outerWindowEstimations;
        },
        getRoofWindowEstimations(state) {
            return state.roofWindowEstimations;
        },
        getRoofEstimations(state) {
            return state.roofEstimations;
        },
        getTopFloorEstimations(state) {
            return state.topFloorEstimations;
        },
        getBasementEstimations(state) {
            return state.basementEstimations;
        },
        getMoreEstimation(state) {
            return state.moreEstimation;
        },
        getBuildingTechnologyEstimation(state) {
            return state.buildingTechnologyEstimation;
        }
    }
});

const estimationDefaultState: DetailedEstimation = {
    totalEstimation: {
        area: 0,
        energyClass: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        errorHeatLossPerBasicArea: 0,
        heatLossPerBasicArea: 0,
        lowerBoundEnergyClass: '',
        upperBoundEnergyClass: ''
    },
    wallEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    floorEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    doorEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    outerWindowEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    roofWindowEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    roofEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        outerArea: 0,
        totalArea: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    topFloorEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    basementEstimations: [{
        buildingPartKey: '',
        errorHeatLoss: 0,
        heatLoss: 0,
        area: 0,
        areaError: 0,
        uError: 0,
        uValue: 0
    }],
    moreEstimation: {
        heatingSource: 0,
        waterSource: 0,
        internalEnergyWin: 0,
        takenAssumption: 0,
        temperatureFactor: 0,
        totalAssumption: 0,
        usable_area: 0,
        ventilationHeatLoss: 0,
        volume: 0
    },
    buildingTechnologyEstimation: {
        heatingEffortNumber: 0,
        heatingEnergy: 0,
        heatingTank: 0,
        waterEffortNumber: 0,
        waterEnergy: 0,
        waterTank: 0
    }
}