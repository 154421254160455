export default class Config {
    public static backendBaseUrl = process.env.VUE_APP_ROPE_BACKEND;

    public static loginUrl = process.env.VUE_APP_ROPE_LOGIN;

    public static logoutUrl = process.env.VUE_APP_ROPE_LOGOUT;

    public static registerUrl = process.env.VUE_APP_ROPE_REGISTER;

    public static basicAuthCookieName = btoa('basicAuthToken');

    public static setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;

        // Set it expire in 1 day
        date.setTime(date.getTime() + (24 * 60 * 60 * 1000));

        // Set it
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    }

    public static getCookie(name: string) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");

        if (parts.length === 2) {
            return parts.pop()?.split(";").shift();
        }
    }
}
