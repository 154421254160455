
import {defineComponent, ref} from 'vue';
import NavigationBar from '@/navigation/components/NavigationBar.vue';
import Footer from "@/info/views/Footer.vue";
import BackToTopButton from "@/navigation/components/BackToTopButton.vue";

export default defineComponent({
  components: {Footer, NavigationBar, BackToTopButton},
  data() {
    return {
      mainElement: {},
    }
  },
  mounted() {
    this.mainElement = this.$refs['mainElement'] as HTMLElement;
  }
});

