import Config from '@/config';
import {Conversation} from '@/messaging/model/conversation';
import {ConversationOverview} from '@/messaging/model/conversationOverview';
import {CustomFolder} from '@/messaging/model/customFolder';
import {Message} from '@/messaging/model/message';
import {MessageFilter} from '@/messaging/model/messageFilter';
import {MessageRepository} from '@/messaging/repository/messageRepository';

interface JsonMessageRepresentation {
    messageKey: string,
    sendingUserKey: string,
    receivingUserKey: string,
    conversationKey: string,
    sendingTimestamp: string,
    subject: string,
    messageBody: string,
    isSend: boolean,
    isMarked: boolean,
    includesSharing: boolean
}

interface JsonConversationRepresentation {
    conversationKey: string,
    messages: JsonMessageRepresentation[]
}

export default class MessageRepositoryImpl implements MessageRepository {

    private messagesBackendUrl = `${Config.backendBaseUrl}/api/messages`;

    async getFolders(): Promise<CustomFolder[]> {

        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<CustomFolder[]>(`${this.messagesBackendUrl}/folders`, requestParams);
    }

    async getConversationOverviews(messageFilter: MessageFilter, folderKey: string): Promise<ConversationOverview[]> {

        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        const filterNameString = this.mapMessageFilterToString(messageFilter);
        const requestUrl = `${this.messagesBackendUrl}?filter=${filterNameString}&folderKey=${folderKey}`

        const jsonResponse: Array<any> = await this.executeRequest<Array<any>>(requestUrl, requestParams);

        return jsonResponse.map(conv => ({
            conversationKey: conv.conversationKey,
            numberOfMessages: conv.numberOfMessages,
            latestReceivedMessage: this.mapJsonMessageResponseToDomain(conv.latestReceivedMessage),
            draftKey: conv.draftKey,
            isFavorite: conv.isFavorite
        }));
    }

    async getSingleConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<Conversation> {

        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        const filterNameString = this.mapMessageFilterToString(messageFilter);
        const requestUrl = `${this.messagesBackendUrl}/conversation/${conversationKey}?filter=${filterNameString}&folderKey=${folderKey}`

        const jsonResponse: JsonConversationRepresentation
            = await this.executeRequest<JsonConversationRepresentation>(requestUrl, requestParams);

        return {
            conversationKey: jsonResponse.conversationKey,
            messages: jsonResponse.messages.map(m => this.mapJsonMessageResponseToDomain(m))
        };
    }

    async sendMessage(message: Message): Promise<boolean> {

        const requestUrl = `${this.messagesBackendUrl}/sendMessage`;
        const requestParams: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(message),
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);

    }

    async moveMessage(messageKey: string, messageFilter: MessageFilter): Promise<boolean> {

        const requestBody = {
            messageKey: messageKey,
            messageFilter: messageFilter,
        }

        const requestUrl = `${this.messagesBackendUrl}/moveMessage`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(requestBody),
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async moveConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string, currentFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {

        const requestBody = {
            conversationKey: conversationKey,
            messageFilter: messageFilter,
            targetFolderKey: folderKey,
            currentFilter: currentFilter,
            currentFolderKey: currentFolderKey
        }

        const requestUrl = `${this.messagesBackendUrl}/moveConversation`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(requestBody),
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async unmarkMessage(messageKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/unmarkMessage?messageKey=${messageKey}`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async unmarkConversation(conversationKey: string, currentMessageFilter: MessageFilter, currentFolderKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/unmarkConversation?conversationKey=${conversationKey}&currentMessageFilter=${currentMessageFilter.valueOf()}&currentFolderKey=${currentFolderKey}`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async deleteMessage(messageKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/deleteMessage?key=${messageKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async restoreMessage(messageKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/restoreMessage?key=${messageKey}`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async deleteConversation(conversationKey: string, messageFilter: MessageFilter, folderKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/deleteConversation?conversationKey=${conversationKey}&messageFilter=${messageFilter.valueOf()}&folderKey=${folderKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async restoreConversation(conversationKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/restoreConversation?key=${conversationKey}`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async createCustomFolder(folderName: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/createCustomFolder?folderName=${folderName}`;
        const requestParams: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async deleteCustomFolder(folderKey: string): Promise<boolean> {
        const requestUrl = `${this.messagesBackendUrl}/deleteCustomFolder?folderKey=${folderKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private mapJsonMessageResponseToDomain(jsonMessageRepresentation: JsonMessageRepresentation): Message {
        return {
            messageKey: jsonMessageRepresentation.messageKey,
            sendingUserKey: jsonMessageRepresentation.sendingUserKey,
            receivingUserKey: jsonMessageRepresentation.receivingUserKey,
            conversationKey: jsonMessageRepresentation.conversationKey,
            sendingTimestamp: jsonMessageRepresentation.sendingTimestamp ? new Date(Date.parse(jsonMessageRepresentation.sendingTimestamp)) : null,
            subject: jsonMessageRepresentation.subject,
            messageBody: jsonMessageRepresentation.messageBody,
            isSend: jsonMessageRepresentation.isSend,
            isMarked: jsonMessageRepresentation.isMarked,
            includesSharing: jsonMessageRepresentation.includesSharing
        }
    }

    private mapMessageFilterToString(messageFilter: MessageFilter): string {
        switch (messageFilter) {
            case MessageFilter.SEND:
                return 'SEND';
            case MessageFilter.DRAFTS:
                return 'DRAFTS';
            case MessageFilter.MARKED:
                return 'MARKED';
            case MessageFilter.TRASH_BIN:
                return 'RECYCLE_BIN';
            case MessageFilter.CUSTOM_FOLDER:
                return 'CUSTOM_FOLDER';
            default:
                return 'INBOX';
        }
    }

}
