import NavigationService from '@/navigation/navigationService';
import User from "@/navigation/model/user";

export default class NavigationPort {
    private navigationService: NavigationService;

    constructor(navigationService: NavigationService) {
        this.navigationService = navigationService;
    }

    getAuthenticatedUser(): Promise<User | null> {
        return this.navigationService.getAuthenticatedUser();
    }

    getRouter() : unknown {
        return this.navigationService.getRouter();
    }
}
