export default {
  Intro: 1,
  AllBasics: 20,
  Location: 30,
  Consumption: 40,
  AllWalls: 50,
  AllFloor: 60,
  AllWindows: 70,
  AllRoof: 80,
  AllTechnology: 90,
  // DetailedResults: 10,
  LastPage: 95,
  ImageBasedPropertyRegistration: 96,

  //Help-Page-Index
  HelpBasicBasic: 111,
  HelpBasicPowerUsage: 112,
  HelpBasicLocation: 113,
  HelpBasicFloor: 114,
  HelpBasicPlan: 115,
  HelpBasicAdditionals: 116,

  HelpWallsDesignType: 121,
  HelpWallsInsulation: 122,
  HelpWallsOuterWall: 123,
  HelpWallsInnerWall: 124,

  HelpFloorBasement: 131,
  HelpFloorDesignType: 132,
  HelpFloorInsulation: 133,

  HelpWindowsWall: 141,
  HelpWindowsRoof: 142,
  HelpWindowsDoor: 143,

  HelpRoofAtticUsage: 151,
  HelpRoofInsulation: 152,
  HelpRoofType: 153,

  HelpTechnologyHeatingType: 161,
  HelpTechnologyPhotovoltaic: 162,
  HelpTechnologyVentilation: 163,
  HelpTechnologyWater: 164,

  HelpDetailEfficiencyCurrent: 171,
  HelpDetailPossibilities: 172,
  HelpDetailEfficiencyAfter: 173,

  // Image Categories
  BasicImages: 81,
  WindowImages: 82,

  TechnologyHeating: 91,
  TechnologyWater: 92,
  TechnologyVentilation: 93,
  TechnologyPhotovoltaic: 94,

  DetailedResults: 95,

  BasicsBasic: 21,
  BasicsLocation: 22,
  BasicsFloorPlan: 23,
  BasicsAdditionals: 24
}