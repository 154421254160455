import {FundingProgramsPort} from '@/fundingPrograms/fundingProgramsPort';
import {FundingProgramsService} from '@/fundingPrograms/fundingProgramsService';
import {FundingProgramsRepositoryImpl} from '@/fundingPrograms/repository/fundingProgramsRepositoryImpl';
import {MockFundingProgramsRepository} from '@/fundingPrograms/repository/mockFundingProgramsRepository';

function createFundingProgramsPort(): FundingProgramsPort {
    return new FundingProgramsPort(new FundingProgramsService(new FundingProgramsRepositoryImpl()));
}

export { createFundingProgramsPort }
