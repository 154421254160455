import User from '@/navigation/model/user';
import NavigationRepository from '@/navigation/repository/navigationRepository';

export default class NavigationService {
    private navigationRepository: NavigationRepository;

    constructor(navigationRepository: NavigationRepository) {
        this.navigationRepository = navigationRepository;
    }

    async getAuthenticatedUser(): Promise<User | null> {
        return this.navigationRepository.getAuthenticatedUser();
    }

    getRouter() : unknown {
        return this.navigationRepository.getRouter();
    }






}
