import SortDirection from '@/common/table/types/sortDirection';
import {PaginatedFundingProgramResult} from '@/fundingPrograms/model/paginatedFundingProgramResult';
import FundingProgramsRepository from '@/fundingPrograms/repository/fundingProgramsRepository';
import SortBy from '@/fundingPrograms/model/sortBy';

export class FundingProgramsService {

    private fundingProgramsRepository: FundingProgramsRepository;


    constructor(fundingProgramsRepository: FundingProgramsRepository) {
        this.fundingProgramsRepository = fundingProgramsRepository;
    }

    public async searchFundingPrograms(searchTerm: string,
                                       measureFilter: string,
                                       propertyFilter: string,
                                       sortBy: SortBy,
                                       sortDirection: SortDirection,
                                       page: number,
                                       pageSize: number): Promise<PaginatedFundingProgramResult> {

        return this.fundingProgramsRepository.searchFundingPrograms(searchTerm, measureFilter, propertyFilter, sortBy, sortDirection, page, pageSize);
    }

}
